import React from 'react';
import '../assets/css/loader.css' 
const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;