
import '../../assets/css/Process.css';
import ProcessCenter from "./ProcessCenter";
import ProcessHeader from "./ProcessHeader.js";

const Process = () => {
  return (
    <>
      <ProcessHeader />
      <ProcessCenter />
    </>
  );
};
export default Process;   
