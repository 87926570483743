import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme';
import './assets/css/App.css';
import Footer from './components/Footer/Footer';
import NewsFeed from './components/News/NewsFeed';
import Navbarmenu from './components/NavBarMenu/Navbarmenu';
import Home from './components/Home/Home';
import ContactUs from './components/ContactUs/Contactus';
import Process from './components/Process/Process';
import Products from './components/Products/Products';
import Aboutus from './components/AboutUs/Aboutus';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Router basename="/">
          <Navbarmenu />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Home" component={Home} />
            <Route exact path="/Aboutus" component={Aboutus} />
            <Route path="/Process" component={Process} />
            <Route path="/Products" component={Products} />
            <Route path="/Contactus" component={ContactUs} />
            <Route path="/News" component={NewsFeed} />
          </Switch>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
